@tailwind base;
@tailwind components;
@tailwind utilities;

.chevron {
  position: relative;
  top: -1px;
  content: "";
  display: inline-block;
  width: 7px;
  height: 7px;
  border-right: 1px solid black;
  border-top: 1px solid black;
  transform: rotate(135deg);
}

.pixelated {
  image-rendering: pixelated;
}
